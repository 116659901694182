<template>
    <v-app class="fullimg-bg blog-single">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container>
                    <v-row v-for="(item, index) in post" v-bind:key="index">
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-card :loading="loading" class="mx-auto card-inner">
                                        <template slot="progress">
                                            <v-progress-linear color="white" height="10" indeterminate></v-progress-linear>
                                        </template>
                                        <h1 class="single-title mb-4">{{ item.title.rendered }}</h1>
                                        <div>
                                            <v-img :style="{ height: imageHeight + 'px' }" :src="item.featured_media_img" class="featured_media">
                                                <div class="justify-center">
                                                    <h3 class="blog-date">{{ item.date_formated}}</h3>
                                                </div>
                                            </v-img>
                                        </div>
                                        <div style="padding-left: 10px;background: #211f1f;">
                                            <v-chip-group class="justify-end" column>
                                                <div item.categories v-for="(cat, i) in item.categories_data" v-bind:key="i">
                                                    <v-chip color="#000000" :href="cat.category_url" class="catchip" label link>{{ cat.name }}</v-chip>
                                                </div>
                                            </v-chip-group>
                                        </div>
                                        <v-card-text style="background: #211f1f !important;text-align: left;">
                                            <div v-html="item.content.rendered"></div>
                                        </v-card-text>
                                        <v-divider class="mx-4"></v-divider>
                                    </v-card>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="featured-border mb-8 mb-md-16 mt-8 mt-md-16"><span class="subheader font-helvetica">Other Categories</span>
                        <div class="border-left"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                        <v-row class="pt-3 pt-sm-11 pb-1 pb-sm-11 flex-direction-row-tb flex-direction-column-m">
                            <v-col cols="12" sm="12" md="12">
                                <template>
                                    <v-chip-group class="justify-end" column>
                                        <div item.categories v-for="(cat, i) in cats" v-bind:key="i">
                                            <v-chip color="#000000" :href="cat.category_url" class="catchip lgchip" label link large>{{ cat.name }} ({{ cat.category_count }})</v-chip>
                                        </div>
                                    </v-chip-group>
                                </template>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </section>
        </v-main>
        <FooterBar />
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppBlogSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
        mounted: false,
        imageHeight: null,
        cats: [],
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then(res => {
                    this.post = res.data;
                }, error => {
                    console.log(error);
                });

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/categories/1')
                .then(res => {

                    this.cats = res.data;

                }, error => {
                    console.log(error);
                });

            this.mounted = true

        }

        this.mounted = true
        window.addEventListener('resize', this.setheight)
        this.setheight()

    },
    methods: {
        setheight() {

            //let image = event.target;    
            this.imageHeight = window.innerWidth * .2;

            if (window.innerWidth > 960)
                this.imageHeight = 250

            if (this.imageHeight < 150)
                this.imageHeight = 150

            //console.log(window.innerWidth, this.imageHeight);
        }

    },
}
</script>
<style>
</style>