import { render, staticRenderFns } from "./MenuOptions.vue?vue&type=template&id=85c9d0d0"
import script from "./MenuOptions.vue?vue&type=script&lang=js"
export * from "./MenuOptions.vue?vue&type=script&lang=js"
import style0 from "./MenuOptions.vue?vue&type=style&index=0&id=85c9d0d0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports