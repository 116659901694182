<template>
    <v-app>
        <v-main touchless>
            <section class="homesection-hello pt-3">
                <TopNavBar />
                <v-container>
                    <v-row>
                        <v-col class="col-lg-12">
                            <div class="pa-0">
                                <h1>Hello, We're <span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span></span></h1>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="section1-row1 pt-0 pb-0">
                        <v-col class="col-lg-4 col-md-6 col-sm-12 border-right pr-4 pt-0 pb-0">
                            <v-img id="funnel_rounded_animated" src="../images/Desktops/Home/Home_Top_HeroSection/funnel_rounded_animated.svg"></v-img>
                        </v-col>
                        <v-col class="col-lg-5 col-md-6 col-sm-12 pl-4 pt-4 pb-4 our-devs">
                            <h4 class="font-helvetica smaller">
                                Blockchain Security
                            </h4>
                            <h4 class="font-helvetica orange-text smaller our-devs-clients">
                                <vue-typer :text='["The fortress of the digital world.","Securing tomorrow, one block at a time.","Hackers beware: The age of blockchain security is here!","Guarding the digital realm, one block at a time.","Cyber threats meet their match"]'></vue-typer>
                            </h4>
                            <v-btn color="primary" class="mt-6 home pulse-btn" target="_blank" href="https://calendly.com/macnerd/macnerdconsult" title="Schedule FREE consultation">
                                FREE consultation
                            </v-btn>
                        </v-col>
                        <v-col class="col-lg-3 col-md-6 col-sm-12 pr-4 pt-4 pb-4 triangle-wrapper">
                            <v-img id="Triangles_animated" src="../images/Desktops/Home/Home_Top_HeroSection/Triangles_animated.svg"></v-img>
                        </v-col>
                    </v-row>
                    <v-row class="mt-8">
                        <v-col class="col-lg-12 mt-6 pa-0">
                            <template>
                                <v-sheet class="mx-auto home_sheet" elevation="0">
                                    <v-slide-group v-model="model" class="pa-0" show-arrows>
                                        <v-slide-item v-slot="{ active, toggle }" v-for="(item, index) in posts" v-bind:key="index">
                                            <v-card :color="active ? 'primary' : 'white lighten-1'" class="ma-2 bg slide-posts" height="260" @click="toggle">
                                                <v-row class="fill-height" align="center" justify="center">
                                                    <v-icon v-if="active" color="white" size="48" v-text="'mdi-close-circle-outline'"></v-icon>
                                                    <v-card-title class="blog-posts" v-else>{{ item.title.rendered }}</v-card-title>
                                                </v-row>
                                            </v-card>
                                        </v-slide-item>
                                    </v-slide-group>
                                    <v-expand-transition>
                                        <v-sheet v-if="model != null" tile>
                                            <v-row class="sheet-desc fill-height" align="center" justify="center">
                                                <h4 v-html="posts[model].title.rendered" class="pt-2 text-center"></h4>
                                                <div v-html="posts[model].excerpt.rendered.replace('[&hellip;]', '')" class="body-text pt-2" style="text-align: center;"></div>
                                                <div class="pt-2">
                                                    <v-btn color="#0085ff" class="mt-2 pulse-btn" medium :href="posts[model].link">
                                                        Read More
                                                    </v-btn>
                                                </div>
                                            </v-row>
                                        </v-sheet>
                                    </v-expand-transition>
                                </v-sheet>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="col-lg-12 mt-10">
                            <v-img width="100" src="../images/Desktops/Home/Home_Top_HeroSection/Down_Arrow.png" class="data-center pulse-btn" @click="goto('homesection-services')" style="cursor: pointer;"></v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="homesection-services" ref="homesection-services">
                <div class="sub-section clr">
                    <div class="pa-0 mt-2 mb-0">
                        <v-row class="text-center">
                            <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pa-4 pb-0 services-title">
                                <h2><span class="">Our </span><span class="black-text">Services</span></h2>
                                <p class="black-text">We care about transparent, secure and global solutions.</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" v-for="(item, index) in ourservice" v-bind:key="index">
                                <v-card class="mx-auto" max-width="450" min-height="650px" color="#211f1f">
                                    <div class="ourservice-wrapper">
                                        <v-lazy>
                                            <!--<v-img :src="item.image" width="95px"></v-img>-->
                                            <v-icon v-if="index==0" color="#ec7c40" class="ourservice-icon">mdi-clipboard-check-multiple-outline</v-icon>
                                            <v-icon v-if="index==1" color="#ec7c40" class="ourservice-icon2">mdi-shield-check</v-icon>
                                            <v-icon v-if="index==2" color="#ec7c40" class="ourservice-icon2">mdi-magnify-expand</v-icon>
                                        </v-lazy>
                                    </div>
                                    <div>
                                        <v-card-text>
                                            <div class="ourservice-text-wrapper">
                                                <h3>{{item.header}}</h3>
                                                <p v-html="item.content" class="ourservice-text"></p>
                                            </div>
                                            <v-spacer />
                                            <div v-if="showServiceButtons">
                                                <v-btn class="btn-hover color-blue" target="_blank" :title="'MacNerd - ' + item.button_text" :href="item.button_link">
                                                    <v-icon color="#ffffff" style="margin-right:.5em;">fas fa-download</v-icon> {{item.button_text}}
                                                </v-btn>
                                            </div>
                                            <div v-else>
                                                <v-btn class="btn-hover color-blue" title="Learn More" href="https://macnerd.io/contact-us/">
                                                    <span style="text-decoration: none;">
                                                        <v-icon color="#ffffff" style="margin-right:.5em;">fa-solid fa-chevron-right</v-icon>
                                                    </span> Learn More
                                                </v-btn>
                                            </div>
                                        </v-card-text>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </section>
            <section class="homesection-expertise">
                <div class="sub-section clr pt-10">
                    <div class="sub-section-pad">
                        <v-row class="mt-0 pb-0" no-gutters>
                            <v-col class="col-lg-2 col-md-2 col-sm-2 border-right-white">
                                <div>
                                    <h2 class="pl-2">Our</h2>
                                </div>
                            </v-col>
                            <v-col class="col-lg-4 col-md-4 col-sm-9">
                                <h2><span class="orange-text pl-2">Expertise</span></h2>
                            </v-col>
                            <v-col class="col-lg-6 col-md-6 col-sm-12 pa-2">
                            </v-col>
                        </v-row>
                        <v-row class="border-top-white mt-0 pb-8 block-section" no-gutters>
                            <v-col class="col-lg-2 col-sm-12 border-right-white pa-2 data-center text-center border-bottom-white-sm border-left-white-sm">
                                <a @click="toggleBlockchain" class="white-links pulse-font" ref="toggleBlockchainLink">Blockchain</a>
                            </v-col>
                            <v-col class="col-lg-2 col-sm-12 border-right-white pa-2 data-center text-center border-bottom-white-sm border-left-white-sm">
                                <a @click="toggleSecurity" class="white-links pulse-font" ref="toggleSecurityLink">Security</a>
                            </v-col>
                            <v-col class="col-lg-2 col-sm-12 pa-2 data-center text-center border-right-white border-bottom-white-sm border-left-white-sm"><a @click="toggleUX" class="white-links pulse-font" ref="toggleUXLink">UX Design</a>
                            </v-col>
                            <v-col class="col-lg-3 col-sm-12 pa-2 data-center text-center border-right-white-sm border-bottom-white-sm border-left-white-sm"><a @click="toggleBackend" class="white-links pulse-font" ref="toggleBackendLink">Backend Engineering</a>
                            </v-col>
                            <v-col class="col-lg-3 col-sm-12 pa-2 sm-hide">
                            </v-col>
                            <v-expand-transition>
                                <v-sheet class="our-expertise-sheets" v-if="modelBlockchain != null" tile style="">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <p class="text-h6">
                                            <span class="orange-text">Blockchain</span> is a system of recording information in a way that makes it difficult or impossible to change, hack, or cheat the system. A blockchain is essentially a digital ledger of transactions that is duplicated and distributed across the entire network of computer systems on the blockchain.
                                        </p>
                                        <p class="text-h6">We develop smart contracts that are programs stored on a blockchain that run when predetermined conditions are met. They typically are used to automate the execution of an agreement so that all participants can be immediately certain of the outcome, without any intermediary's involvement or time loss.
                                        </p>
                                    </v-row>
                                </v-sheet>
                                <v-sheet class="our-expertise-sheets" v-if="modelSecurity != null" tile style="">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <p class="text-h6">
                                            <span class="orange-text">Security</span> measures are designed to combat threats against networked systems and applications, whether those threats originate from inside or outside of an organization. We take security seriously and do regular penetration testing on all of our projects. If a security issue arises we will work with cyber crime authorities.
                                        </p>
                                    </v-row>
                                </v-sheet>
                                <v-sheet class="our-expertise-sheets" v-if="modelUX != null" tile style="">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <p class="text-h6">
                                            <span class="orange-text">UX Design (UX)</span> is the process of designing a good user experience by making, creating, and organizing the interfaces people interact with daily, like mobile apps, websites, and applications.
                                        </p>
                                    </v-row>
                                </v-sheet>
                                <v-sheet class="our-expertise-sheets" v-if="modelBackend != null" tile style="">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <p class="text-h6">
                                            <span class="orange-text">Backend Engineering</span> is responsible for designing, building, and maintaining the server-side of web applications. In other words, a backend engineer’s primary responsibility is to build the structure of a software application.</p>
                                        <p class="text-h6">This sets the software team's foundations of what they need to do to achieve the main goals. This means that one of the primary functions is to write business logic, server scripts, and APIs that will later be used by the other developers and members of the team.</p>
                                    </v-row>
                                </v-sheet>
                            </v-expand-transition>
                        </v-row>
                        <v-lazy>
                            <div class="our-expertise" ref="our-expertise-img">
                            </div>
                        </v-lazy>
                    </div>
                </div>
            </section>
            <section class="homesection-clients" ref="homesection-clients">
                <v-lazy>
                    <div class="sub-section clients">
                        <div class="pa-0 mt-16 mb-0">
                            <v-row class="pa-10 pb-4 pt-4">
                                <v-col class="col-lg-7 col-md-6 col-sm-12 col-xs-12 pa-4 pb-0 our-clients-title">
                                    <h2 class="bigger text-center"><span class="large-break">Our </span><span class="orange-text">Partners</span></h2>
                                </v-col>
                                <v-col class="col-lg-5 col-md-6 col-sm-12 col-xs-12 pa-4 pl-10 data-center text-center border-left-orange">
                                    <template>
                                        <v-card class="mx-auto" elevation="0">
                                            <v-container>
                                                <v-item-group v-model="selected" multiple>
                                                    <v-row>
                                                        <v-col v-for="(item, i) in items" :key="i" lg="6" md="6" sm="6" cols="12">
                                                            <a target="_blank" :href="`${item.url}`">
                                                                <v-item class="pulse-btn">
                                                                    <v-img :src="`${item.home_image}`" aspect-ratio="1" class="grey lighten-2">
                                                                        <template v-slot:placeholder>
                                                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                                                            </v-row>
                                                                        </template>
                                                                    </v-img>
                                                                </v-item>
                                                            </a>
                                                        </v-col>
                                                    </v-row>
                                                </v-item-group>
                                            </v-container>
                                        </v-card>
                                    </template>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-lazy>
            </section>
            <section class="homesection-expertise">
                <div class="sub-section clr pt-10">
                    <div class="sub-section-pad">
                        <v-row>
                            <v-col class="v-col-lg-12 v-col-md-12 v-col-sm-12 v-col-xs-12">
                                <div class="pt-0">
                                    <div class="">
                                        <!-- form div -->
                                        <div class="pa-0 mt-0" v-if="displayForm">
                                            <div>
                                                <v-form @submit.prevent="onSubmit" ref="refForm" v-model="form">
                                                    <!-- begin form -->
                                                    <v-row>
                                                        <v-col class="v-col-lg-12 v-col-md-12 v-col-sm-12 v-col-xs-12 text-center">
                                                            <h2 class="mb-4">
                                                                <span class="blue-text">Want to Learn more about <span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span></span>?</span>
                                                            </h2>
                                                            <!-- Company Information Card title -->
                                                            <v-card class="mx-auto pa-2" elevation="0" max-width="1200px">
                                                                <v-row>
                                                                    <v-col class="v-col-lg-6 v-col-md-6 v-col-sm-12 v-col-xs-12 pa-2 pr-2" cols="12">
                                                                        <v-text-field label="Email" variant="outlined" v-model="formData.coEmail" :rules="emailRules" required density="compact" class="label-left" color="#36ab4e"></v-text-field>
                                                                    </v-col>
                                                                    <v-col class="v-col-lg-6 v-col-md-6 v-col-sm-12 v-col-xs-12 pa-2 pr-2" cols="12">
                                                                        <v-text-field label="Company Name" variant="outlined" v-model="formData.coName" density="compact" class="label-left" color="#36ab4e" required :rules="nameRules"></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col class="v-col-lg-12 v-col-md-12 v-col-sm-12 v-col-xs-12 pa-2 pr-2" cols="12">
                                                                        <v-text-field label="Comments" variant="outlined" v-model="formData.coComments" density="compact" class="label-left" color="#36ab4e"></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row v-if="loading" class="mt-0">
                                                                    <v-col class="v-col-lg-12 v-col-md-12 v-col-sm-12 v-col-xs-12 pa-4 pl-2 pt-8">
                                                                        <v-progress-circular indeterminate color="#36ab4e"></v-progress-circular>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row v-else class="mt-6">
                                                                    <v-col class="v-col-lg-12 v-col-md-12 v-col-sm-12 v-col-xs-12 pa-4 pt-2">
                                                                        <v-btn type="submit" color="#36ab4e" style="font-size: 1.1em;height: 2.7em;padding:.5em 1em" x-large elevation="2" class="mt-2 mr-2" :disabled="!form" :loading="loading">
                                                                            <span class="mdi mdi-information" style="margin-right: 0.25em;color:#ffffff;font-size: 1.5em;"></span>
                                                                            <span style="color:#ffffff;">PLEASE SEND ME INFO</span>
                                                                        </v-btn>
                                                                        <span></span>
                                                                        <v-btn href="https://calendly.com/macnerd/macnerdconsult" target="_blank" color="primary" style="font-size: 1.1em;height: 2.7em;padding:.5em 1em" x-large elevation="2" class="mt-2 mr-2">
                                                                            <span class="mdi mdi-calendar-multiple-check" style="margin-right: 0.25em;color:#ffffff;font-size: 1.5em;"></span>
                                                                            <span style="color:#ffffff;">Free consultation!</span>
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
//import lock from './plugins/scroll-lock'
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBarAlt.vue'
import { VueTyper } from 'vue-typer'
import axios from 'axios'
export default {
    name: 'AppHome',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar, "VueTyper": VueTyper },
    data: () => ({
        //debug: false,
        width: 300,
        widthsm: 200,
        post: [],
        posts: [],
        model: null,
        modelBlockchain: null,
        modelSecurity: null,
        modelUX: null,
        modelBackend: null,
        items: [],
        selected: [],
        ourservice: [],
        siteurl: 'https://' + process.env.VUE_APP_HOST_NAME + '/contact-us/',
        siteurl2: 'https://' + process.env.VUE_APP_HOST_NAME + '/about/',
        siteurl3: 'https://' + process.env.VUE_APP_HOST_NAME + '/work/',
        showServiceButtons: true,
        title: "Persistent Vulnerability Assessment",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "Valid E-mail is required",
        ],
        nameRules: [
            (v) => !!v || "Value is required",
            (v) =>
            (v && v.length >= 3) || "Value must be greater than 3 characters",
        ],
        required: [(v) => !!v || "Field is required"],
        subjectRules: [(v) => !!v || "Must select one option"],
        loading: false,
        // display
        displayForm: true,
        // form
        form: false,
        formData: {
            coEmail: '',
            coName: '',
            coComments: '',
            hash: '',
        },

    }),
    created() {},
    async mounted() {
        // get page data
        let id = document.querySelector('meta[name="pageid"]').content;
        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
            .then(res => {
                //console.log(response.data);
                this.post = res.data[0];
                this.ourservice = res.data[0].acf.our_service;
                this.items = res.data[0].acf.our_client_images;

            }, error => {
                console.log(error);
            });

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/posts/1')
            .then(res => {
                //console.log(response.data);
                this.posts = res.data;
            }, error => {
                console.log(error);
            });

        window.addEventListener('touchmove', this.preventDefault, { passive: false });

        this.toggleSecurity();
        this.formData.hash = this.makeid(20)

    },
    unmounted() {

        window.removeEventListener('touchmove', this.preventDefault, { passive: false });

    },
    methods: {
        async onSubmit() {

            if (this.$refs.refForm.validate()) {
                this.loading = true;

                let postPageURL = 'https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/learnmore';

                await axios
                    .post(postPageURL, this.formData)
                    .then((response) => {
                        console.log(response)
                        alert("Thanks for learning more about MacNerd! Please check your email.");
                        window.location.reload(true)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        },
        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo({
                top: top,
                behavior: 'smooth',
            })
        },
        preventDefault(e) {
            if (this.state.swiping || this.state.scale !== 1) {
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        },
        async toggleBlockchain() {

            this.modelSecurity = null;
            this.modelUX = null;
            this.modelBackend = null;
            let refName = 'toggleBlockchain';

            this.$refs['toggleSecurityLink'].classList.value = 'white-links';
            this.$refs['toggleUXLink'].classList.value = 'white-links';
            this.$refs['toggleBackendLink'].classList.value = 'white-links';

            if (this.modelBlockchain == true) {
                this.modelBlockchain = null;
                this.$refs[refName + 'Link'].classList.value = 'white-links';
            } else {
                this.modelBlockchain = true;
                this.$refs[refName + 'Link'].classList.value = 'white-links open';
            }
        },
        async toggleSecurity() {

            this.modelBlockchain = null;
            this.modelUX = null;
            this.modelBackend = null;
            let refName = 'toggleSecurity';

            this.$refs['toggleBlockchainLink'].classList.value = 'white-links';
            this.$refs['toggleUXLink'].classList.value = 'white-links';
            this.$refs['toggleBackendLink'].classList.value = 'white-links';

            if (this.modelSecurity == true) {
                this.modelSecurity = null;
                this.$refs[refName + 'Link'].classList.value = 'white-links';
            } else {
                this.modelSecurity = true;
                this.$refs[refName + 'Link'].classList.value = 'white-links open';
            }
        },
        async toggleUX() {

            this.modelSecurity = null;
            this.modelBlockchain = null;
            this.modelBackend = null;
            let refName = 'toggleUX';

            this.$refs['toggleBlockchainLink'].classList.value = 'white-links';
            this.$refs['toggleSecurityLink'].classList.value = 'white-links';
            this.$refs['toggleBackendLink'].classList.value = 'white-links';

            if (this.modelUX == true) {
                this.modelUX = null;
                this.$refs[refName + 'Link'].classList.value = 'white-links';
            } else {
                this.modelUX = true;
                this.$refs[refName + 'Link'].classList.value = 'white-links open';
            }
        },
        async toggleBackend() {

            this.modelSecurity = null;
            this.modelUX = null;
            this.modelBlockchain = null;
            let refName = 'toggleBackend';

            this.$refs['toggleBlockchainLink'].classList.value = 'white-links';
            this.$refs['toggleSecurityLink'].classList.value = 'white-links';
            this.$refs['toggleUXLink'].classList.value = 'white-links';

            if (this.modelBackend == true) {
                this.modelBackend = null;
                this.$refs[refName + 'Link'].classList.value = 'white-links';
            } else {
                this.modelBackend = true;
                this.$refs[refName + 'Link'].classList.value = 'white-links open';
            }
        },

    },
}
</script>
<style>
</style>