<template>
    <v-row align="center" justify="center" class="alt-footer">
        <v-col class="text-center pa-0" cols="12">
            <span v-for="item in menuItems" :key="item.title">
                <v-btn text :href="item.path" v-if="item.path!=false" target="_blank" :title="item.titleHover">
                    <span class="footer-text mr-4 ml-4">{{ item.title }}</span>
                </v-btn>
                <span v-else>
                    <span class="footer-text mr-4 ml-4">{{ item.title }}</span> 
                </span>
            </span>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "FooterNavBar",
    data() {
        return {
            appTitle: 'MacNerd',
            menuItems: [
                { title: 'Twitter', path: 'https://twitter.com/MacNerdENT', titleHover: "View our Twitter feed", spacer: true },
                { title: 'TikTok', path: 'https://www.tiktok.com/@macnerdent', spacer: true, titleHover: "View our TikTok videos" },
                { title: 'Instagram', path: 'https://www.instagram.com/macnerdent', spacer: true, titleHover: "View our Instagram" },
                { title: 'Privacy Policy', path: 'https://macnerd.io/privacy-policy/', spacer: false, titleHover: "View our Privacy Policy" },


            ]
        }
    },
};
</script>
<style>
</style>