import Vue from 'vue'
/* eslint-disable*/
import Home from './AppHome.vue'
import About from './AppAbout.vue'
import Blog from './AppBlog.vue'
import BlogSingle from './AppBlogSingle.vue'
import Careers from './AppCareers.vue'
import Contactus from './AppContactus.vue'
import ContactusDemos from './AppContactusDemos.vue'
import PageSingle from './AppPageSingle.vue'
import PageSingleAlt from './AppPageSingleAlt.vue'
import BlogCategory from './AppBlogCategory.vue'
import Clients from './AppClients.vue'
import App404 from './App404.vue'
/* eslint-enable */
import vuetify from './plugins/vuetify'
Vue.config.productionTip = false
import VueNavigationBar from './components/vue-navigation-bar/vue-navigation-bar';
import './components/vue-navigation-bar/dist/vue-navigation-bar.css';
Vue.component("vue-navigation-bar", VueNavigationBar);
import VueLuxon from "vue-luxon";
Vue.use(VueLuxon, {
    input: {
        zone: "America/New_York",
        format: "yyyy-MM-dd HH:mm"
    },
    output: {
        zone: "local",
        format: {
            year: "numeric",
            month: "long",
            day: "numeric"
        },
    },
    icons: {
        iconfont: 'mdi' || 'fa' || 'far'
    },
});

const mnTheme = {
    themes: {
        light: {
            primary: '#0085ff',
            secondary: '#ec7c40',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
        },
        //dark: {},
    },
};

let pageTemplate = document.querySelector('meta[name="pagetemplate"]').content;
let template ='PageSingle';

if (pageTemplate.length > 0 && document.getElementById("app") ) {

    if (pageTemplate =='PageSingle')
        template = PageSingle;

    else if (pageTemplate =='PageSingleAlt')
        template = PageSingleAlt;

    else if (pageTemplate =='Home')
        template = Home;

    else if (pageTemplate =='About')
        template = About;

    else if (pageTemplate =='Blog')
        template = Blog;

    else if (pageTemplate =='BlogSingle')
        template = BlogSingle;
    
    else if (pageTemplate =='Careers')
        template = Careers;
    
    else if (pageTemplate =='Contactus')
        template = Contactus;

    else if (pageTemplate =='Demo')
        template = ContactusDemos;

    else if (pageTemplate =='BlogCategory')
        template = BlogCategory;

    else if (pageTemplate =='Clients')
        template = Clients;

    else if (pageTemplate =='404')
        template = App404;

    else if (pageTemplate =='')
        template = BlogSingle;

    else
        template = App404;


    new Vue({
        vuetify,
        theme: mnTheme,
        render: h => h(template)
    }).$mount('#app')

} else if ( document.getElementById("app-blog-category") ) {

    template = BlogCategory;

    new Vue({
        vuetify,
        theme: mnTheme,
        render: h => h(template)
    }).$mount('#app-blog-category')


} else if ( document.getElementById("app-blog-single") ) {

    template = BlogSingle;

    new Vue({
        vuetify,
        theme: mnTheme,
        render: h => h(template)
    }).$mount('#app-blog-single')

 } else {

    template = App404;

    new Vue({
        vuetify,
        theme: mnTheme,
        render: h => h(template)
    }).$mount('#app')

}
