<script>
export default {
    name: "dynamic-link",
    render: function(h) {

        if (typeof this.path != "undefined") {
            return h("a", { attrs: { href: this.path } }, this.$slots.default);
        } else {

            return h("div", {}, this.$slots.default);

        }

    },
    props: {
        isUsingVueRouter: {
            type: Boolean,
            required: true
        },
        path: {
            type: [String],
            required: true
        },
        isLinkAction: {
            type: Boolean,
            required: true
        }
    }
};
</script>