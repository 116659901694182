<template>
    <v-app class="fullimg-bg">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-card class="mx-auto" style="min-height:500px">
                                        <v-row class="text-center">
                                            <v-col cols="12">
                                                <h1><span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span></span></h1>
                                            </v-col>
                                        </v-row>
                                        <v-row style="text-align: center;">
                                            <v-col cols="12">
                                                <div>
                                                    <h4>Page Not Found</h4>
                                                    <p>Please check your browser url and try again.</p>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mx-4"></v-divider>
                                    </v-card>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBarAlt.vue'
export default {
    name: 'App404',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],

    }),
    created() {},
    mounted() {

    },
    methods: {


    },
}
</script>
<style>
</style>