<template>
    <v-app class="contact-us-app">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container class="contact_us_background pa-0">
                    <v-form ref="form" lazy-validation>
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" sm="12" md="6" lg="6" class="form-wrapper ma-0 pa-4">
                                <v-row>
                                    <v-col cols="12">
                                        <h3>Contact <span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span> </span></h3>
                                    </v-col>
                                </v-row>
                                <div v-if="formCompleted" style="min-height:700px;">
                                    <v-alert type="success">
                                        <p class="white-text">Success! Thank you for contacting MacNerd. We will get back to you as soon as we can. Have a great day!</p>
                                        <p>
                                            <v-btn @click="resetForm()" color="white" large class="mt-0 black--text">
                                                <v-icon color="#ffffff" small style="margin-right:.5em;color:#000000;">mdi-arrow-back-circle</v-icon> Reset Form
                                            </v-btn>
                                        </p>
                                    </v-alert>
                                </div>
                                <div v-else>
                                    <v-row>
                                        <v-col cols="12">
                                            <h5>I'm interested in...</h5>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="col-lg-6 col-sm-12 col-xs-12">
                                            <v-btn class="pl-2 pr-2" @click="development()" outlined v-bind:style="{color: `${developmentBtn}`}"> Blockchain Development </v-btn>
                                        </v-col>
                                        <v-col class="col-lg-6 col-sm-12 col-xs-12">
                                            <v-btn class="pl-2 pr-2" @click="consulting()" outlined v-bind:style="{color: `${cryptoBtn}`}"> Crypto Forensics </v-btn>
                                        </v-col>
                                        <v-col class="col-lg-6 col-sm-12 col-xs-12">
                                            <v-btn class="pl-2 pr-2" @click="media_inquiries()" outlined v-bind:style="{color: `${smartBtn}`}"> Project Security </v-btn>
                                        </v-col>
                                        <v-col class="col-lg-6 col-sm-12 col-xs-12">
                                            <v-btn class="pl-2 pr-2" @click="other()" outlined v-bind:style="{color: `${otherBtn}`}"> Blockchain Architecture Security </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field color="white" label="Your Name" v-model="opts.name" :rules="nameRules"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field color="white" label="Your Company" v-model="opts.company"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field color="white" label="Your Email" v-model="opts.email" :rules="emailRules"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field color="white" label="Tell Us About Your Project" v-model="opts.about"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-col cols="12" class="mb-5">
                                        <v-progress-circular v-if="showmoreActive" indeterminate color="primary"></v-progress-circular>
                                        <v-btn v-else @click="submitForm()" color="primary pulse-btn home" large class="mt-6 home">
                                            Submit
                                        </v-btn>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </section>
        </v-main>
        <FooterBar />
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppContactUs',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        debug: false,
        cryptoT: false,
        cryptoBtn: 'white',
        developmentT: false,
        developmentBtn: 'white',
        smartT: false,
        smartBtn: 'white',
        otherT: false,
        otherBtn: 'white',
        post: [],
        opts: { intrest: '', name: '', company: '', email: '', about: '', hash: '' },
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Valid E-mail is required'
        ],
        nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length >= 3) || 'Name must be greater than 3 characters',
        ],
        showmoreActive: false,
        formCompleted: false


    }),
    created() {},
    mounted() {
        this.development();
    },
    methods: {

        resetForm() {

            this.formCompleted = false;
            this.showmoreActive = false;
            this.opts = { subject: [], name: '', email: '', hash: '' }
            this.opts.subject.push('Custom Software Development');

        },

        hasher(str, seed = 0) {
            let h1 = 0xdeadbeef ^ seed,
                h2 = 0x41c6ce57 ^ seed;
            for (let i = 0, ch; i < str.length; i++) {
                ch = str.charCodeAt(i);
                h1 = Math.imul(h1 ^ ch, 2654435761);
                h2 = Math.imul(h2 ^ ch, 1597334677);
            }
            h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
            h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
            return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        },

        async submitForm() {

            if (this.$refs.form.validate()) {

                this.showmoreActive = true;

                if (this.developmentT)
                    this.opts.intrest = this.opts.intrest + 'Blockchain Development, '

                if (this.cryptoT)
                    this.opts.intrest = this.opts.intrest + 'Crypto Forensics, '

                if (this.smartT)
                    this.opts.intrest = this.opts.intrest + 'Smart Contract & DAPP Audits, '

                if (this.otherT)
                    this.opts.intrest = this.opts.intrest + 'Other '

                this.opts.hash = this.hasher(Math.random())
                //console.log(this.opts);

                let postPageURL = 'https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/sendmail';

                await axios
                    .post(postPageURL, this.opts)
                    .then(() => {
                        //console.log(response)
                        //alert("Thanks for contacting MacNerd! We will get back to you as soon as we can.");
                        setTimeout(function() { this.formCompleted = true; }.bind(this), 2000)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                

            }

        },

        consulting() {

            this.cryptoT = !this.cryptoT;

            if (this.cryptoBtn == 'white')
                this.cryptoBtn = '#ec7c40';
            else
                this.cryptoBtn = 'white';


        },
        development() {

            this.developmentT = !this.developmentT;

            if (this.developmentBtn == 'white')
                this.developmentBtn = '#ec7c40';
            else
                this.developmentBtn = 'white';


        },
        media_inquiries() {

            this.smartT = !this.smartT;

            if (this.smartBtn == 'white')
                this.smartBtn = '#ec7c40';
            else
                this.smartBtn = 'white';


        },
        other() {

            this.otherT = !this.otherT;

            if (this.otherBtn == 'white')
                this.otherBtn = '#ec7c40';
            else
                this.otherBtn = 'white';

        },

    },
}
</script>
<style>
</style>