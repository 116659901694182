<template>
    <v-app class="fullimg-bg">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container>
                    <v-row v-for="(item, index) in post" v-bind:key="index">
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-card :loading="loading" class="mx-auto">
                                        <template slot="progress">
                                            <v-progress-linear color="white" height="10" indeterminate></v-progress-linear>
                                        </template>
                                        <v-row class="text-center">
                                            <v-col cols="12">
                                                <h1>Who Is <span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span></span></h1>
                                            </v-col>
                                        </v-row>
                                        <v-row style="background: #211f1f !important;min-height:500px;text-align: left;">
                                            <v-col cols="12">
                                                <div v-html="item.content.rendered"></div>
                                                <div>
                                                    <v-btn color="primary" class="mt-6 home pulse-btn clients" :href="siteurl" title="join us to learn more">
                                                        Join us...
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mx-4"></v-divider>
                                    </v-card>
                                </template>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <h2 class="single-title text-center"><span style="color:#ffffff;">Team</span></h2>
                            <div class="page-body-clients" style="padding-top:0;">
                                <v-row v-for="(client, i) in item.acf.our_team" v-bind:key="i">
                                    <v-col cols="12" class="pa-0">
                                        <div class="body-text pt-0 page-body-inner">
                                            <template>
                                                <div class="pa-2 pb-0 pl-4 pr-4">
                                                    <h4 class="orange-text">{{ client.name }}</h4>
                                                    <h4>{{ client.title }}</h4>
                                                </div>
                                                <div class="pa-2 pl-4 pr-4" v-html="client.description"></div>
                                            </template>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="text-center">
                        <v-col>
                            <h2>Our <span class="orange-text"><span class="flicker-fast">P</span>i<span class="flicker-med">ll</span>ar<span class="flicker-slow">s</span></span></h2>
                        </v-col>
                    </v-row>
                    <v-row style="background: #211f1f !important;min-height:500px;text-align: left;">
                        <v-col cols="12">
                            <v-lazy>
                                <div class="our-pillars-engineering">
                                    <div class="pa-0 mt-0"></div>
                                </div>
                            </v-lazy>
                            <h4 class="pillars-title">1. Engineering</h4>
                            <p>We believe that innovation is essential in product design. Our team provides brilliant solutions to complex problems through simple, effective and beautiful program design.</p>
                        </v-col>
                        <v-col cols="12">
                            <v-lazy>
                                <div class="ourpillars-sub-section-clear our-pillars-security">
                                </div>
                            </v-lazy>
                            <h4 class="pillars-title">2. Security</h4>
                            <p>Blockchain technology is the most secure programming medium in history. We patrol the pathways to and from the blockchain to ensure transactional stability between platforms.</p>
                        </v-col>
                        <v-col cols="12">
                            <v-lazy>
                                <div class="ourpillars-sub-section-clear our-pillars-ux">
                                </div>
                            </v-lazy>
                            <h4 class="pillars-title">3. UX Design</h4>
                            <p>Our team has a long history of making powerful, capable software that is easy and familiar for user interaction. We integrate our solutions into your existing day-to-day operations seamlessly.</p>
                        </v-col>
                    </v-row>
                    <v-row class="text-center">
                        <v-col cols="12">
                            <h2>Our <span class="orange-text"><span class="flicker-fast">V</span>a<span class="flicker-med">l</span>ue<span class="flicker-slow">s</span></span></h2>
                        </v-col>
                    </v-row>
                    <v-row style="background: #211f1f !important;min-height:500px;text-align: left;">
                        <v-col cols="12">
                            <div>
                                <div class="pa-0">
                                    <p><span class="orange-text">Trust:</span> We value trusted relationships both internally and externally. Our staff and clients are valued partners with whom we require an equal commitment to doing the right thing. Open communication and altruism are key.</p>
                                    <p><span class="orange-text">Transparency:</span> The blockchain is a beautiful example of transparency, and we desire to reflect that as a company. All business dealings (internal and external) should be “above board” and open to scrutiny.</p>
                                    <p><span class="orange-text">Growth:</span> At MacNerd, we believe companies that grow together stay together. Indeed, the very existence of MacNerd arose from the need for our parent company to grow into a separate Web3 based entity. Our goal is to help all of our clients transition into Web3 as innovation allows.</p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="text-center">
                        <v-col cols="12" class="pa-4 pb-8">
                         
                                <h2>We LOVE protecting the <span class="orange-text"><span class="flicker-fast">F</span>u<span class="flicker-med">t</span>ur<span class="flicker-slow">e</span></span></h2>
                                <h4 class="future-h4 text-center">Securing tomorrow, one block at a time.</h4>
                                <v-btn color="primary" class="mt-6 home pulse-btn" target="_blank" href="https://calendly.com/macnerd/macnerdconsult" title="Schedule FREE consultation">
                                    FREE consultation
                                </v-btn>
                           
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBarAlt.vue'
import axios from 'axios'
export default {
    name: 'AppAbout',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
        siteurl: 'https://' + process.env.VUE_APP_HOST_NAME + '/contact-us/',
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
            .then(res => {
                console.log(res.data);
                this.post = res.data;
            }, error => {
                console.log(error);
            });

    },
    methods: {
        setTab(component) {
            //console.log(this.post);
            //console.log(this.tabs);
            this.currentComponent = component;
        },

    },
}
</script>
<style>
</style>