<template>
    <!-- items without sub menus -->
    <div v-if="!option.subMenuOptions || !option.subMenuOptions.length">
        <v-hover v-slot="{ hover }" open-delay="20">
            <v-btn text class="topnav-item" :href="option.path">
                <span class="top-nav-item" :style="{ 'text-decoration': hover ? 'underline' : 'none', 'color': option.activeColor }">{{ option.text }}</span>
            </v-btn>
        </v-hover>
        <!--<span v-if="!option.lastNavItem" class="vnb__menu-options__option__button__icon vnb__menu-options__option__button__icon--right">/</span>-->
    </div>
    <!-- top items with subs -->
    <span v-else class="vnb__menu-options__option__link sub" :id="'dropdown-menu-parent-' + option.id" aria-haspopup="true" :aria-expanded="isExpanded ? 'true' : 'false'" :aria-label="option.text" tabindex="0">
        <v-hover v-slot="{ hover }" open-delay="20">
            <v-btn class="topnav-item" text :href="option.path">
                <span class="top-nav-item" :style="{ 'text-decoration': hover ? 'underline' : 'none', 'color': option.activeColor }">{{ option.text }}</span>
            </v-btn>
        </v-hover>
        <!--<span class="vnb__menu-options__option__button__icon vnb__menu-options__option__button__icon--right">/</span>-->
        <!-- sub items -->
        <div dark class="vnb__sub-menu-options" v-if="option.type === 'link' && option.subMenuOptions != 'undefined' && option.subMenuOptions.length > 0" :id="'sub-menu-options-' + option.id" style="background-color: #211f1f !important;background: #211f1f !important;border: 1px solid #ffffff;">
            <div class="vnb__sub-menu-options__option" tabindex="-1">
                <div :path="subOption.path" v-for="(subOption, index) in option.subMenuOptions" :key="index" class="vnb__sub-menu-options__option__link" @click.native="
            subMenuItemSelected(subOption.text);
            $emit('vnb-item-clicked', subOption.text);
          " :aria-label="subOption.text" tabindex="0" @keydown.tab.native="subMenuItemTabbed(subOption.text)" >
                    <v-hover v-slot="{ hover }" open-delay="20">
                        <a class="vnb__sub-menu-options__option__link__text-wrapper" :href="subOption.path">
                            <span class="vnb__sub-menu-options__option__link__text-wrapper__text" :style="{ 'color': hover ? '#0085ff' : '#ffffff' }">{{ subOption.text }}</span>
                            <span v-if="subOption.subText" class="vnb__sub-menu-options__option__link__text-wrapper__sub-text">{{ subOption.subText }}</span>
                        </a>
                    </v-hover>
                </div>
            </div>
        </div>
    </span>
</template>
<script>
import "tippy.js/themes/light.css";
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/perspective.css';
import tippy, { hideAll } from "tippy.js";

export default {
    name: "desktop-menu-item-link",
    props: {
        option: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            currentExpandedStatus: "closed"
        };
    },
    computed: {
        isExpanded() {
            if (this.currentExpandedStatus === "open") {
                return true;
            }

            return false;
        }
    },
    methods: {
        // Each time a sub-menu-option is clicked, close all the tooltips
        subMenuItemSelected(text) {
            console.log(text);
            this.closeAllTooltips();
        },

        // When we keydown tab on the last sub-menu-option, we want to close
        // all the tooltips
        subMenuItemTabbed(text) {
            // Let's check to see if this item is the last
            // item in the subMenuOptions array
            if (
                this.option.subMenuOptions[this.option.subMenuOptions.length - 1]
                .text === text
            ) {
                this.closeAllTooltips();
            }
        },

        menuShown() {
            this.currentExpandedStatus = "open";
        },
        menuHidden() {
            this.currentExpandedStatus = "closed";
        },

        closeAllTooltips() {
            // https://atomiks.github.io/tippyjs/v6/methods/#hideall
            hideAll();
        },

        initTippy() {
            let el = document.getElementById(
                "dropdown-menu-parent-" + this.option.id
            );

            const template = document.getElementById(
                "sub-menu-options-" + this.option.id
            );
            template.style.display = "block";

            tippy(el, {
                theme: "light",
                content: template,
                interactive: true,
                animation: this.options.tooltipAnimationType,
                role: "Menu",
                // trigger: 'click', // for testing
                trigger: "click mouseenter focus",
                appendTo: "parent",
                arrow: true,
                inertia: false,
                placement: this.options.tooltipPlacement,
                popperOptions: {
                    modifiers: [{
                        name: 'flip',
                        options: {
                            fallbackPlacements: [this.options.tooltipPlacement]
                        }
                    }]
                },
                onShow: (instance) => {
                    hideAll({ exclude: instance });

                    // fire the menuShown function
                    this.menuShown();
                },
                onHide: () => {
                    // fire the menuHidden function
                    this.menuHidden();
                }
            });
        }
    },
    mounted() {
        // Let's setup our tippy here in mounted
        if (this.option.subMenuOptions && this.option.subMenuOptions.length) {
            this.initTippy();
        }
    },
    components: {
        //DynamicLink
    }
};
</script>
<style lang="scss">
@import "../assets/css/main.scss";

.vnb {
    &__menu-options {
        &__option {
            &__link {
                cursor: pointer;
                font-weight: 500;
                color: lighten($black, 15%);
                transition: color 0.2s ease-in;
                display: flex;
                flex-direction: row;
                align-items: left;
                justify-content: left;
                font-size: 0.9rem;

                &:hover {
                    color: $black;
                    text-decoration: none;
                }

                &__icon {
                    svg {
                        max-height: 20px;
                    }

                    &--left {
                        margin-right: 5px;
                    }

                    &--right {
                        margin-left: 5px;
                    }
                }
            }

            &__arrow {
                max-height: 5px;
                max-width: 25px;
                transition: transform 0.2s ease-in-out;

                &--hover {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__sub-menu-options {
        background: #211F1F;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius;
        padding: 10px 0;

        &__option {
            min-width: 250px;
            max-width: 300px;

            &__link {
                padding: 12px 12px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: $black;
                transition: color 0.2s ease-in;
                border-left: 0;

                &:hover {
                    // color: $black;
                    color: white;
                    text-decoration: none;
                    // background: lighten($grey, 2%);
                    // border-left: 2px solid $blue;
                    background: #211F1F;
                    cursor: pointer;
                }

                &__icon {
                    svg {
                        max-height: 40px;
                    }

                    &--left {
                        margin-right: 15px;
                    }

                    &--right {
                        margin-left: 15px;
                    }
                }

                &__text-wrapper {
                    width: 100%;

                    &__text {
                        display: block;
                        text-align: left;
                    }

                    &__sub-text {
                        margin-top: 5px;
                        display: block;
                        font-size: 0.8rem;
                        text-align: left;
                        color: $black;
                    }
                }
            }

        }
    }
}

.vnb__menu-options__option {}

.topnav-item {
    width: auto;
    padding: 10px 15px;

}

.vnb__menu-options__option__link {

    padding: 0 0 !important;
}

.vnb__menu-options__option__button__icon--right {
    margin-left: 0 !important;
}
</style>
<style>
.top-nav-item {
    padding-left: 4px !important;
}

.top-nav-item {
    padding-right: 4px !important;
}

@media (max-width: 1264px) {

    .top-nav-item {
        padding-left: 3 !important;
    }

    .top-nav-item {
        padding-right: 3 !important;
    }

    .v-btn:not(.v-btn--round).v-size--default {
        padding: 0 4px;
    }
}
</style>