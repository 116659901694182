var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$vssWidth > _vm.options.mobileBreakpoint)?_c('div',{class:[
    'vnb__menu-options',
    { 'vnb__menu-options--right': _vm.type === 'left' },
    { 'vnb__menu-options--right': _vm.type === 'right' }
  ]},_vm._l((_vm.type === 'left'
      ? _vm.options.menuOptionsLeft
      : _vm.options.menuOptionsRight),function(option,index){return _c('div',{key:index,staticClass:"vnb__menu-options__option"},[(option.type === 'link')?_c('desktop-menu-item-link',{attrs:{"option":option,"options":_vm.options},on:{"vnb-item-clicked":_vm.vnbItemClicked}}):(option.type === 'button')?_c('desktop-menu-item-button',{attrs:{"option":option,"options":_vm.options},on:{"vnb-item-clicked":_vm.vnbItemClicked}}):_c('desktop-menu-item-spacer',{attrs:{"option":option}})],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }