<template>
    <v-app class="fullimg-bg blog-single">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container v-for="(item, index) in post" v-bind:key="index">
                    <v-row>
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-card :loading="loading" class="mx-auto card-inner">
                                        <template slot="progress">
                                            <v-progress-linear color="white" height="10" indeterminate></v-progress-linear>
                                        </template>
                                        <h1 class="single-title"><span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span> </span> <span style="color:#ffffff;">{{ item.title.rendered }}</span></h1>
                                        <div class="page-body-clients mt-4">
                                            <v-row v-for="(client, i) in item.acf.our_work" v-bind:key="i">
                                                <v-col cols="12" class="pa-0">
                                                    <div class="body-text pt-0 page-body-inner">
                                                        <template>
                                                            <v-img height="450" :src="client.image"></v-img>
                                                            <div class="pa-2 pb-0 pl-4 pr-4 pt-4">
                                                                <h4 class="orange-text">{{ client.title }}</h4>
                                                            </div>
                                                            <div class="pa-2 pl-4 pr-4" v-html="client.description"></div>
                                                            <div class="pt-2 pb-8" style="text-align: center;">
                                                                <v-btn color="primary " class="mt-2 home pulse-btn" style="padding: 1em 2em" :href="client.url">
                                                                    View Partner
                                                                </v-btn>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </v-main>
        <FooterBar />
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBarAlt.vue'
import axios from 'axios'
export default {
    name: 'AppClients',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then(res => {
                    //console.log(response.data);
                    this.post = res.data;
                }, error => {
                    console.log(error);
                });

        }

    },
    methods: {},
}
</script>
<style>
</style>