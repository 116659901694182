import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
    theme: {
        
        themes: {
            dark: {
                primary: '#0085ff',
                secondary: '#ec7c40',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
        dark: true,
    },
}

export default new Vuetify(opts)