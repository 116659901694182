<template>
    <nav class="vnb" :id="finalOptions.elementId" :aria-label="finalOptions.ariaLabelMainNav">
        <v-container class="pa-0 ma-0">
            <v-row class="pa-0 ma-0" style="width:100%">
                <v-col class="col-lg-4 col-md-4 col-sm-11 pa-0 ma-0">
                    
                    <a v-if="$vssWidth < options.mobileBreakpoint" :href="siteurl" title="MacNerd Home" style="margin-left:20px;"><img src="../../../images/MacNerd_Logo_Small-transparent.png" height="75" style="cursor: pointer;" /></a>

                    <a v-else :href="siteurl" title="MacNerd Home"><img src="../../../images/MacNerd_Logo-White-web-sm.png" height="75" style="cursor: pointer;" /></a>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="col-lg-6 col-md-6 col-sm-1 pa-0 ma-0">
                    <menu-options :options="finalOptions" :type="'left'" @vnb-item-clicked="vnbItemClicked" />
                    <slot v-if="$vssWidth > options.mobileBreakpoint" name="custom-section"></slot>
                    <menu-options :options="finalOptions" :type="'right'" @vnb-item-clicked="vnbItemClicked" />
                    <div class="text-right" style="padding-top:15px;">
                    <button v-if="$vssWidth < options.mobileBreakpoint" class="vnb__collapse-button" @click="showMobilePopup" type="button" :aria-expanded="(menuIsVisible) ? 'true' : 'false'">
                        <img v-if="options.collapseButtonImageOpen" :src="options.collapseButtonImageOpen" :alt="'Menu'" class="vnb__collapse-button__image">
                        <svg v-else height="100px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100" width="150px" xmlns="http://www.w3.org/2000/svg" class="vnb__collapse-button__image" :style="{ fill: '#0085ff' }">
                            <title>Menu</title>
                            <g transform="matrix(.1 0 0 -.1 0 100)">
                                <path d="m0 850v-40h500 500v40 40h-500-500z" />
                                <path d="m0 495v-45h500 500v45 45h-500-500z" />
                                <path d="m0 140v-40h500 500v40 40h-500-500z" />
                            </g>
                        </svg>
                    </button>
                    </div>
                    <popup dark v-if="
                            finalOptions.menuOptionsLeft.length ||
                              finalOptions.menuOptionsRight.length
                          " :options="finalOptions" :menuIsVisible="menuIsVisible" @close-button-clicked="closeMobilePopup" @vnb-item-clicked="vnbItemClicked">
                        <template v-slot:custom-section>
                            <slot name="custom-section"></slot>
                        </template>
                    </popup>
                </v-col>
            </v-row>
        </v-container>
    </nav>
</template>
<script>
    import VueScreenSize from "vue-screen-size";
import MenuOptions from "./components/MenuOptions.vue";
import Popup from "./components/Popup.vue";

export default {
    name: "vue-navigation-bar",
    mixins: [VueScreenSize.VueScreenSizeMixin],
    props: {
        options: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            siteurl: 'https://' + process.env.VUE_APP_HOST_NAME + '/',
            menuIsVisible: false,
            windowSize: {
                x: 0,
                y: 0,
            },
        };
    },
    computed: {
        finalOptions() {
            // What we're doing here is giving each top-level menu-option a unique id
            if (this.options.menuOptionsLeft) {
                for (let x = 0; x < this.options.menuOptionsLeft.length; x++) {
                    this.$set(this.options.menuOptionsLeft[x], "id", this.uuidV4());
                }
            }
            if (this.options.menuOptionsRight) {
                for (let x = 0; x < this.options.menuOptionsRight.length; x++) {
                    this.$set(this.options.menuOptionsRight[x], "id", this.uuidV4());
                }
            }

            return {
                elementId: this.options.elementId ? this.options.elementId : this.uuidV4(),
                isUsingVueRouter: this.options.isUsingVueRouter ? true : false,
                mobileBreakpoint: this.options.mobileBreakpoint ?
                    this.options.mobileBreakpoint : 960,
                brandImagePath: this.options.brandImagePath ?
                    this.options.brandImagePath : "/",
                brandImage: this.options.brandImage ? this.options.brandImage : null,
                brandImageAltText: this.options.brandImageAltText ?
                    this.options.brandImageAltText : "brand-image",
                collapseButtonImageOpen: this.options.collapseButtonImageOpen ?
                    this.options.collapseButtonImageOpen : null,
                collapseButtonImageClose: this.options.collapseButtonImageClose ?
                    this.options.collapseButtonImageClose : null,
                collapseButtonOpenColor: this.options.collapseButtonOpenColor ?
                    this.options.collapseButtonOpenColor : '#373737',
                collapseButtonCloseColor: this.options.collapseButtonCloseColor ?
                    this.options.collapseButtonCloseColor : '#373737',
                showBrandImageInMobilePopup: this.options.showBrandImageInMobilePopup ?
                    true : false,
                ariaLabelMainNav: this.options.ariaLabelMainNav ?
                    this.options.ariaLabelMainNav : "Main Navigation",
                tooltipAnimationType: this.options.tooltipAnimationType ?
                    this.options.tooltipAnimationType : "shift-away",
                tooltipPlacement: this.options.tooltipPlacement || "bottom",
                menuOptionsLeft: this.options.menuOptionsLeft ?
                    this.options.menuOptionsLeft : [],
                menuOptionsRight: this.options.menuOptionsRight ?
                    this.options.menuOptionsRight : []
            };
        }
    },
    methods: {
        onResize() {

            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
            //console.log(this.windowSize.y, this.menuIsVisible);
            if (this.windowSize.y < 960) {
                this.menuIsVisible = false;
            }
        },
        collapseButtonClicked() {
            console.log('collapse-button-clicked');
            this.$emit('collapse-button-clicked')
        },
        uuidV4() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        closeMobilePopup() {
            this.menuIsVisible = false;
            this.$emit("vnb-mobile-popup-hidden");
        },
        showMobilePopup() {
            this.menuIsVisible = true;
            this.$emit("vnb-mobile-popup-shown");
        },
        vnbItemClicked(text) {
            this.$emit("vnb-item-clicked", text);
        }
    },
    components: {
        MenuOptions,
        Popup
    },
    created() {
        window.addEventListener("resize", this.onResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
};
</script>
<style lang="scss">
@import "./assets/css/main.scss";

.vnb {

    &__collapse-button {
        cursor: pointer;
        border: 0;
        background: transparent;
        margin-right: 10px;

        &:hover {
            opacity: 0.75;
        }

        &__image {
            max-height: 30px;
            max-width: 30px;
        }
    }

    background-color: transparent !important;

    * {
        box-sizing: border-box;
    }

    background: transparent !important;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
        text-decoration: none;
    }

    @media (min-width: 960px) {

        .button-red {
            background: #ff3b30;

            &:hover {
                background: transparent;
            }
        }
    }

    &__menu-options {
        &__option {
            &__button {
                &__icon {
                    svg {
                        margin-top: -3px;
                    }
                }
            }
        }
    }

    &__popup {
        &__bottom {
            &__menu-options {
                &__option {
                    &__link {
                        &__icon {
                            svg {
                                margin-top: -4px;
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-section-content {
        width: 100%;

        @media (min-width: 568px) {
            width: 50%;
        }

        @media (min-width: 960px) {
            width: 15%;
        }

        @media (min-width: 1200px) {
            width: 20%;
        }
    }
}

.tippy-tooltip {
    padding: 0;
}

.vnb-image {
    max-width: 100%;
    height: auto;
}

.vnb__collapse-button {

    margin-right: 0;
    margin-left: 10px !important;
    padding-bottom: 12px !important;

}
</style>
<style type="text/css">
@media (min-width: 1500px) {
    .vnb {
        width: 100%;
        min-width: 1500px;
    }
}

@media (max-width: 1500px) {
    .vnb {
        width: 97vw !important;
        min-width: 97vw !important;
    }
}

@media (max-width: 960px) {
    .vnb {
        width: 100%;
    }
}

#main-navbar{
    padding-top:  0 !important;
    padding-bottom:  0 !important;
    overflow: hidden;
}
</style>