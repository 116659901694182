<template>
    <v-app class="contact-us-app">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container class="contact_us_background pa-0">
                    <v-form ref="form" lazy-validation>
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12" sm="12" md="8" lg="8" class="form-wrapper ma-0 pa-4">
                                <v-row>
                                    <v-col cols="12">
                                        <h3><span class="orange-text"> We Love </span><span class="flicker-fast">S</span>ec<span class="flicker-slow">u</span>rit<span class="flicker-slow">y</span></h3>
                                        <p>Thank you for interest in our samples. You can download the full versions below.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div v-if="formCompleted" style="min-height:500px;">
                                    <v-alert type="success">
                                        <p class="white-text">Success! Thank you for contacting MacNerd. We will get back to you as soon as we can. Have a great day!</p>
                                        <p>
                                            <v-btn @click="resetForm()" color="white" large class="mt-0 black--text">
                                                <v-icon color="#ffffff" small style="margin-right:.5em;color:#000000;">mdi-arrow-back-circle</v-icon> Reset Form
                                            </v-btn>
                                        </p>
                                    </v-alert>
                                </div>
                                <div v-else>
                                    <v-row>
                                        <v-col cols="12">
                                            <h5>I'm interested in...</h5>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="col-lg-6 col-sm-12 col-xs-12">
                                            <v-btn class="pl-2 pr-2" @click="smartcontract()" outlined v-bind:style="{color: `${smartBtn}`}"> Free Full Sample Smart Contract Audit</v-btn>
                                        </v-col>
                                        <v-col class="col-lg-6 col-sm-12 col-xs-12">
                                            <v-btn class="pl-2 pr-2" @click="development()" outlined v-bind:style="{color: `${developmentBtn}`}"> MacNerd Blockchain Development</v-btn>
                                        </v-col>
                                        <v-col class="col-lg-6 col-sm-12 col-xs-12">
                                            <v-btn class="pl-2 pr-2" @click="forensics()" outlined v-bind:style="{color: `${forensicsBtn}`}"> Free Full Sample Crypto Forensics </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field color="white" label="Your Name" v-model="opts.name" :rules="nameRules"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field color="white" label="Your Email" v-model="opts.email" :rules="emailRules"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-col cols="12" class="mb-5">
                                        <v-progress-circular v-if="showmoreActive" indeterminate color="primary"></v-progress-circular>
                                        <v-btn v-else @click="submitForm()" color="primary pulse-btn home" large class="mt-6 home">
                                            Submit
                                        </v-btn>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </section>
        </v-main>
        <FooterBar />
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
export default {
    name: 'AppContactUs',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        debug: false,
        ForensicsT: false,
        smartBtn: 'white',
        developmentT: false,
        developmentBtn: 'white',
        smartT: false,
        forensicsBtn: 'white',
        otherT: false,
        otherBtn: 'white',
        post: [],
        opts: { subject: '', name: '', email: '', hash: ''},
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Valid E-mail is required'
        ],
        nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length >= 3) || 'Name must be greater than 3 characters',
        ],
        showmoreActive: false,
        formCompleted: false


    }),
    created() {},
    mounted() {

        let urlFrom = new URL(location.href).searchParams.get('form');

        if (urlFrom == 'audit') {
            this.smartcontract();
        } else if (urlFrom == 'forensics') {
            this.forensics();
        } else if (urlFrom == 'development') {
            this.development();
        } else {
            this.development();
        }

    },
    methods: {

        resetForm() {

            this.formCompleted = false;
            this.showmoreActive = false;
            this.opts = { subject: '', name: '', email: '', hash: '' }
            //this.opts.subject.push('Custom Software Development');
            //development()

        },

        hasher(str, seed = 0) {
            let h1 = 0xdeadbeef ^ seed,
                h2 = 0x41c6ce57 ^ seed;
            for (let i = 0, ch; i < str.length; i++) {
                ch = str.charCodeAt(i);
                h1 = Math.imul(h1 ^ ch, 2654435761);
                h2 = Math.imul(h2 ^ ch, 1597334677);
            }
            h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
            h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
            return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        },

        submitForm() {

            if (this.$refs.form.validate()) {

                this.showmoreActive = true;

                if (this.developmentT)
                    this.opts.subject = this.opts.subject + 'Blockchain Development, '

                if (this.ForensicsT)
                    this.opts.subject = this.opts.subject + 'Full Sample Crypto Forensics, '

                if (this.smartT)
                    this.opts.subject = this.opts.subject + 'Full Sample Smart Contract Audit, '

                this.opts.hash = this.hasher(Math.random())
                //console.log(this.opts);

                let t = fetch('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/senddemo', {
                    method: 'post',
                    headers: {
                        'x-requested-with': 'XMLHttpRequest'
                    },
                    body: JSON.stringify(this.opts)
                }).then(function() {
                    return true;
                });

                if (t) {
                    setTimeout(function() { this.formCompleted = true; }.bind(this), 1000)
                }

            }

        },

        smartcontract() {

            
            this.smartT = !this.smartT;

            if (this.smartBtn == 'white')
                this.smartBtn = '#ec7c40';
            else
                this.smartBtn = 'white';

        },
        development() {

            this.developmentT = !this.developmentT;

            if (this.developmentBtn == 'white')
                this.developmentBtn = '#ec7c40';
            else
                this.developmentBtn = 'white';

        },
        forensics() {

            this.ForensicsT = !this.ForensicsT;

            if (this.forensicsBtn == 'white')
                this.forensicsBtn = '#ec7c40';
            else
                this.forensicsBtn = 'white';

        },

    },
}
</script>
<style>
</style>