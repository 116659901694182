<template>
    <v-app class="fullimg-bg">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container>
                    <template>
                        <v-row class="text-center" v-for="(item, index) in post" v-bind:key="index">
                            <v-col cols="12">
                                <h2>Join The <span class="orange-text-macnerd"><span class="orange-text-macnerd">MAC<span
                                                class="flicker-fast">N</span>ER<span
                                                class="flicker-slow">D</span></span></span> Team</h2>
                            </v-col>
                            <v-col cols="12">
                                <div v-html="item.content.rendered"></div>
                            </v-col>
                        </v-row>
                        <v-row class="text-center" v-for="(item, index) in post" v-bind:key="index">
                            <!-- <v-col cols="12" sm="12" md="6">
                                <v-lazy>
                                    <img class="mr-3" src="../images/Desktops/Careers/SVG/FullStackDeveloperAsset 2_animated.svg" height="155" />
                                </v-lazy>
                                <v-col cols="12">
                                    <h6 style="color:#ec7c40">{{ item.acf.job_header_1 }} </h6>
                                </v-col>
                                <div v-html="item.acf.job_description_1" v-if="!isHidden" style="text-align: left;"></div>
                            </v-col>   -->
                                <v-col cols="12" sm="12" md="6" offset-md="3">
                                    <v-lazy>
                                        <img class="mr-3"
                                            src="../images/Desktops/Careers/SVG/BlockChainDeveloperAsset 3_animated.svg"
                                            height="155" />
                                    </v-lazy>
                                    <v-col cols="12">
                                        <h6 style="color:#ec7c40">{{ item.acf.job_header_2 }}</h6>
                                    </v-col>
                                    <div v-html="item.acf.job_description_2" v-if="!isHidden" style="text-align: left;">
                                    </div>
                                </v-col>
                        </v-row>
                        <!-- <v-row class="text-center" v-for="(item, index) in post" v-bind:key="index">
                            <v-col cols="12" sm="12" md="6">
                                <v-lazy>
                                    <img class="mr-3" src="../images/Desktops/Careers/SVG/BackEndDatabaseAsset 5_animated.svg" height="155" />
                                </v-lazy>
                                <v-col cols="12">
                                    <h6 style="color:#ec7c40">{{ item.acf.job_header_3 }}</h6>
                                </v-col>
                                <div v-html="item.acf.job_description_3" v-if="!isHidden" style="text-align: left;"></div>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-lazy>
                                    <img class="mr-3" src="../images/Desktops/Careers/SVG/FrontEndDeveloperAsset 4_animated.svg" height="155" />
                                </v-lazy>
                                <v-col cols="12">
                                    <h6 style="color:#ec7c40">{{ item.acf.job_header_4 }}</h6>
                                </v-col>
                                <div v-html="item.acf.job_description_4" v-if="!isHidden" style="text-align: left;"></div>
                            </v-col>
                        </v-row> -->
                        <v-row class="text-center mb-8">
                            <v-col cols="12">
                                <div class="text-center">
                                    <v-btn color="primary " class="mt-6 home pulse-btn" style="padding: 1em 2em"
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSdshMl2REz0o0NYRgy4_TqTcJS6l_c3ceSW72YWFScXvhPF4w/viewform"
                                        target="_blank">
                                        Apply Now
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                </v-container>
            </section>
        </v-main>
        <FooterBar />
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppCareers',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        isHidden: false,
        isHidden2: false,
        isHidden3: false,
        isHidden4: false,
        isHidden5: false,
        deg: 0,
        deg2: 0,
        deg3: 0,
        deg4: 0,
        deg5: 0,

        post: [],
    }),
    created() { },
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then(res => {
                    this.post = res.data;
                }, error => {
                    console.log(error);
                });

        }

    },
    methods: {

        rotation(d) {

            //console.log(d);
            switch (d) {
                default:
                    //console.log(d);
                    break;

                case 1:

                    this.deg += 180;

                    break;
                case 2:

                    this.deg2 += 180;

                    break;
                case 3:

                    this.deg3 += 180;
                    break;
                case 4:

                    this.deg4 += 180;

                    break;
                case 5:

                    this.deg5 += 180;

                    break;
            }
        },


    },
}
</script>
<style></style>