<template>
    <v-app class="blog-app">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container>
                    <v-row class="blog-category">
                        <v-col cols="12 mb-3">
                            <div class="section-body-text-blog pt-2" >
                                <h2 class="font-helvetica"><span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span> </span> <span class="header d-block d-md-inline font-helvetica" v-html="name"> </span></h2>
                                <template>
                                    <div class="featured-border mb-8 mb-md-16"><span class="subheader font-helvetica">Featured</span>
                                        <div class="border-left"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                        <v-row class="pt-3 pt-sm-11 pb-1 pb-sm-11 flex-direction-row-tb flex-direction-column-m">
                                            <v-col v-for="(item, index) in posts.slice(0, 3)" v-bind:key="index" cols="12" md="4">
                                                <template>
                                                    <v-card :loading="loading" class="mx-auto my-0" elevation="0">
                                                        <template slot="progress">
                                                            <v-progress-linear color="white" height="10" indeterminate></v-progress-linear>
                                                        </template>
                                                        <a :href="item.link">
                                                            <v-img height="350" :src="item.featured_media_img" class="featured_media">
                                                            </v-img>
                                                        </a>
                                                        <v-divider class="mx-4"></v-divider>
                                                        <v-card-text class="pl-0 blog-card-text">
                                                            <div class="featured_mediatext_wrapper pl-0 pt-4"><a class="blog-link" :href="item.link"><span class="featured_mediatext font-helvetica">
                                                                        {{ item.title.rendered }}
                                                                    </span></a></div>
                                                            <v-chip-group class="justify-end" column>
                                                                <div item.categories v-for="(cat, i) in item.categories_data" v-bind:key="i">
                                                                    <v-chip color="#000000" :href="cat.category_url" class="catchip" label link>{{ cat.name }}</v-chip>
                                                                </div>
                                                            </v-chip-group>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-row justify="end">
                                        <v-col cols="11" sm="8">
                                            <div class="recent-border"><span class="subheader font-helvetica">Recent</span>
                                                <div class="border-left"></div>
                                                <div class="border-top"></div>
                                                <div class="border-bottom"></div>
                                                <v-row class="">
                                                    <v-col cols="12">
                                                        <template>
                                                            <v-card :loading="loading" class="mx-auto my-0" elevation="0">
                                                                <v-list-item v-for="(item, index) in posts" v-bind:key="index" class="pr-0 pl-0 pb-2 pb-sm-0 mb-sm-2">
                                                                    <v-list-item-content class="pb-0">
                                                                        <div class="recent_mediatext_wrapper pl-0"><span class="recent_mediatext">
                                                                                <a v-if="item.link" :href="item.link" class="font-helvetica text-decoration-none blog-link">
                                                                                    {{ item.title.rendered }}</a>
                                                                                <span v-else class="font-helvetica text-decoration-none">{{ item.title.rendered }}</span>
                                                                            </span></div>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-card>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="showmore" class="mt-0">
                                                    <v-col cols="12">
                                                        <div class="mb-4">
                                                            <v-progress-circular v-if="showmoreActive" indeterminate color="primary"></v-progress-circular>
                                                            <v-btn v-else class="btn-hover color-blue w200" @click="getNewPosts()" title="read more" small>
                                                                <span style="text-decoration: none;"><v-icon color="#ffffff" small style="margin-right:.5em;text-decoration: none;">fas fa-blog</v-icon></span> Read More 
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div class="featured-border mb-8 mb-md-16 mt-8 mt-md-16"><span class="subheader font-helvetica">Other Categories</span>
                                        <div class="border-left"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                        <v-row class="pt-3 pt-sm-11 pb-1 pb-sm-11 flex-direction-row-tb flex-direction-column-m">
                                            <v-col cols="12" sm="12" md="12">
                                                <template>
                                                    <v-chip-group class="justify-end" column>
                                                        <div item.categories v-for="(cat, i) in cats" v-bind:key="i">
                                                            <v-chip color="#000000" :href="cat.category_url" class="catchip lgchip" label link large>{{ cat.name }} ({{ cat.category_count }})</v-chip>
                                                        </div>
                                                    </v-chip-group>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </v-main>
        <FooterBar />
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppBlogCategory',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        loading: false,
        selection: 1,
        posts: [],
        name: '',
        cats: [],
        postPage: 1,
        postPageURL: '',
        showmore: false,
        showmoreActive: false,
    }),
    created() {},
    methods: {

        getNewPosts() {

            this.showmoreActive = true;

            let id = document.querySelector('meta[name="categoryid"]').content;
            this.postPage = this.postPage + 1;
            this.postPageURL = 'https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/cat/' + id + '/' + this.postPage;

            axios.get(this.postPageURL)
                .then(res => {

                    this.posts.push(...res.data)
                    let p = this.posts[0].postsPages
                    //console.log(this.posts[0].postsPages)

                    if (this.postPage >= p) {
                        this.showmore = false
                    } else {
                        this.showmore = true
                    }

                    this.showmoreActive = false;

                }, error => {
                    console.log(error);
                });

        }
    },
    mounted() {

        this.showmoreActive = true;

        let id = document.querySelector('meta[name="categoryid"]').content;
        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/cat/' + id + '/' + this.postPage)
            .then(res => {

                this.posts = res.data
                let p = this.posts[0].postsPages
                console.log(this.posts[0].postsPages)

                if (this.postPage >= p) {
                    this.showmore = false
                } else {
                    this.showmore = true
                }

                this.showmoreActive = false;

            }, error => {
                console.log(error);
            });

        let catname = document.querySelector('meta[name="categoryname"]').content;
        this.name = catname;

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/categories/1')
            .then(res => {

                this.cats = res.data;

            }, error => {
                console.log(error);
            });

    }
}
</script>
<style type="text/css">
</style>