<template>
    <div>
        <vue-navigation-bar :options="navbarOptions">
        </vue-navigation-bar>
    </div>
</template>
<script>
export default {
    name: "TopNavBar",
    data() {
        return {
            appTitle: 'MacNerdNav',
            navbarOptions: {
                elementId: "main-navbar",
                isUsingVueRouter: false,
                mobileBreakpoint: 960,
                brandImagePath: "../../images/MacNerd_Logo-White-web.png",
                brandImage: require("../../images/MacNerd_Logo-White-web.png"),
                brandImageAltText: "MacNerd Enterprises",
                collapseButtonOpenColor: "#661c23",
                collapseButtonCloseColor: "#FFFFFF",
                showBrandImageInMobilePopup: true,
                ariaLabelMainNav: "Main Navigation",
                tooltipAnimationType: "shift-away",
                tooltipPlacement: "bottom",
                menuOptionsLeft: [
                    /*
                    {
                        type: "link",
                        text: "SERVICES",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/services/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/services/',
                        subMenuOptions: [],
                    },
                    */
                     {
                        type: "link",
                        text: "ABOUT",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/about/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/about/',
                        subMenuOptions: [],
                    },
                    {
                        type: "link",
                        text: "OUR PARTNERS",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/partners/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/partners/',
                        subMenuOptions: [],
                    },
                    {
                        type: "link",
                        text: "CAREERS",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/careers/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/careers/',
                        subMenuOptions: [],
                    },
                    {
                        type: "link",
                        text: "BLOG",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/blog/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/blog/',
                        subMenuOptions: [],
                        /*
                        subMenuOptions: [{
                                type: "link",
                                text: "In The Media",
                                subText: "Checkout the latest news from MacNerd",
                                path: 'https://' + process.env.VUE_APP_HOST_NAME + '/media/',
                            }

                        ]
                        */
                    },
                    {
                        type: "link",
                        text: "CONTACT",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/contact-us/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/contact-us/',
                        subMenuOptions: [],
                        lastNavItem: true
                    },
                     {
                        type: "link",
                        text: "FREE CONSULTATION",
                        activeColor: this.getHoverColor('https://calendly.com/macnerd/macnerdconsult'),
                        path: 'https://calendly.com/macnerd/macnerdconsult',
                        subMenuOptions: [],
                        lastNavItem: true
                    },
                    

                ],
                menuOptionsRight: []
            },

        }
    },
    methods: {

        getHoverColor(path) {

            //console.log(path, window.location.href)

            if (path == window.location.href)
                return '#0085ff';
            else
                return '#ffffff';
        }

    },
};
</script>
<style>

    .v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 64px;
    padding: 0 8px !important;
}
</style>