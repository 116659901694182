<template>
    <v-app class="fullimg-bg blog-single">
        <v-main>
            <section class="pt-3">
                <TopNavBar />
                <v-container>
                    <v-row v-for="(item, index) in post" v-bind:key="index">
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-card :loading="loading" class="mx-auto card-inner">
                                        <template slot="progress">
                                            <v-progress-linear color="white" height="10" indeterminate></v-progress-linear>
                                        </template>
                                       <h1 class="single-title mb-4"><span class="orange-text-macnerd">MAC<span class="flicker-fast">N</span>ER<span class="flicker-slow">D</span> </span> <span style="color:#ffffff;">{{ item.title.rendered }}</span></h1>
                                        <v-img height="250" :src="item.featured_media_img" class="featured_media">
                                        </v-img>
                                        <div class="page-body">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="pa-6 pt-2" v-html="item.content.rendered"></div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </v-main>
        <FooterBar />
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBarAlt.vue'
import axios from 'axios'
export default {
    name: 'AppPageSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
            .then(res => {
                //console.log(response.data);
                this.post = res.data;
            }, error => {
                console.log(error);
            });

        }

    },
    methods: {},
}
</script>
<style>
</style>